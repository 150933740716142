
import { DiagramTool } from '@icepanel/app-canvas'
import { DiagramObjectType, DiagramType } from '@icepanel/platform-api-client'
import { Easing, Tween } from '@tweenjs/tween.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import KeyboardShortcut from '@/components/keyboard-shortcut.vue'
import commentDescriptions from '@/modules/comment/helpers/comment-descriptions'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelObjectsMenu from '@/modules/model/components/objects/objects-menu.vue'
import { objectDescriptions, objectIcons } from '@/modules/model/helpers/objects'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

const emptyImage = document.createElement('img')
emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

@Component({
  components: {
    KeyboardShortcut,
    ModelObjectsMenu,
    OrganizationUpgradeMenu
  },
  name: 'EditorToolbar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly selection!: DiagramTool
  @Prop({ default: 'context-diagram' as DiagramType }) readonly type!: DiagramType

  libraryModelCount = -1
  libraryModelCountAnimation: Tween<{ scaleIn: number, scaleOut: number, duration: number }> | null = null
  libraryModelCountScale = 1
  libraryModelCountColor = 'accent'

  objectDescriptions = objectDescriptions
  objectIcons = objectIcons
  commentDescriptions = commentDescriptions

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get modelObjectsMenu () {
    return this.$queryValue('model_objects_menu')
  }

  get organizationLimitModelObjectsReached () {
    return Object.values(this.modelModule.objects).filter(o => o.type !== 'root').length >= this.currentOrganizationLimits.modelObjects
  }

  get toolbarNudgeTop (): number {
    switch (this.type) {
      case 'context-diagram': return 64
      case 'app-diagram': return 96
      case 'component-diagram': return 160
    }
  }

  iconColor (item: DiagramTool) {
    return item === this.selection ? 'accent' : 'grey lighten-3'
  }

  textColor (item: DiagramTool) {
    return item === this.selection ? 'accent--text' : 'grey--text text--lighten-3'
  }

  objectCreateHover (type?: DiagramObjectType, e?: DragEvent & { target?: HTMLElement }) {
    e?.target?.blur()
    e?.dataTransfer?.setDragImage(emptyImage, 0, 0)
    this.$emit('object-create-hover', type)
    e?.preventDefault()
  }

  animateLibraryModelCount (type: 'increase' | 'decrease') {
    if (this.libraryModelCountAnimation) {
      this.libraryModelCountAnimation.stop()
      this.libraryModelCountAnimation = null
    }

    this.libraryModelCountColor = type === 'decrease' ? 'error' : 'accent'

    const popScale = type === 'increase' ? 1.8 : 0.3
    this.libraryModelCountAnimation = new Tween({
      duration: 1,
      scaleIn: 1 * popScale,
      scaleOut: 1
    })
      .to({
        duration: 3,
        scaleIn: 1,
        scaleOut: 1 * popScale
      })
      .easing(Easing.Quartic.Out)
      .duration(400)
      .onUpdate(o => {
        if (o.duration > 2) {
          this.libraryModelCountScale = o.scaleIn
        } else {
          this.libraryModelCountScale = o.scaleOut
        }
      })
      .onComplete(() => {
        this.libraryModelCountColor = 'accent'
        this.libraryModelCountAnimation = null
      })
      .start()
  }
}
